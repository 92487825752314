<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="bi bi-collection display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                  <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Group of Bank &amp; UPI Account Management</small>
              </div>
              <div class="ms-auto">
                <button class="btn btn-outline-info fw-medium me-2" v-if="create_modify_permission" @click="createGroup(currentAccount)">
                  <i class="uil uil-plus font-size-16 me-1"></i> Create New Group
                </button>
                <button class="btn btn-outline-info fw-medium" @click="handlePageChange(currentPage)">
                  <i class="uil uil-redo font-size-16 me-1"></i> Refresh
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive text-nowrap font-size-14 position-relative">
                  <table class="table mt-0 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                    <thead class="text-uppercase">
                      <tr>
                        <th>ID</th>
                        <th>Group Name</th>
                        <th>Bank/UPI Accounts</th>           
                        <th>Merchants</th>                    
                        <th>Total Transactions Today</th>
                        <th>Status</th>
                        <th class="text-center" v-if="create_modify_permission">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!returnData.length && !loading">
                        <td :colspan="create_modify_permission ? 7 : 6" height="300" class="text-center text-muted">
                          <i class="bi bi-collection me-1"></i> No Data Available
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td :colspan="create_modify_permission ? 7 : 6" class="text-center text-muted" height="400">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      
                      <tr v-for="(value, index) in returnData" :key="index" v-show="!loading">
                        <td>
                          #{{value.id  }}
                        </td>
                        <td>
                          {{value.name  }}
                        </td>
                        <td>
                          <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-primary" @click="viewGroup(value)">
                            <i class="bi bi-eye"></i> View Account List ({{ value.bank_list.length }})
                          </a>
                        </td>
                        <td>
                          <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-primary" @click="viewGroupMerchant(value)">
                            <i class="bi bi-diagram-3"></i> View Merchant ({{ value.merchants.length }})
                          </a>
                        </td>
                        <td><span v-if="value.currency">{{ value.currency.symbol }}</span> {{convertCurrencyFormat(value.all_account_transaction_spent,true)}}</td>
                        <td>
                          <span v-if="value.status=='unavailable'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                          <span v-else-if="value.status=='available'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                        </td>
                        <td class="text-center" v-if="create_modify_permission">
                          <div>
                            <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="editGroup(value)" :disabled="disabled">
                            <i class="uil uil-edit"></i></button>
                            <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded ms-2" @click="removeGroup(value)" :disabled="disabled">
                            <i class="uil uil-trash-alt"></i></button>
                          </div>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="row" v-if="returnData.length && !loading">
                <div class="col my-3">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        hide-ellipsis
                        @change="handlePageChange"
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalData"
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            <div class="row">
             
              <div class="col-12 col-md-8">
                <div class="row d-none">
                  <div class="col-12 col-md-auto px-md-1 mb-3">
                    <label class="form-label mb-1">Method</label>
                    <select class="form-select pe-5" id="1" v-model="seletedMethod" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All</option>
                      <option value="auto">Auto</option>
                      <option value="manual">Manual</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 px-md-1 mb-3">
                    <label class="form-label mb-1">Status</label>
                    <select class="form-select pe-5" id="1" v-model="selectedStatus" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Status</option>
                      <option value="processing">Processing</option>
                      <option value="successful">Successful</option>
                      <option value="pending">Pending</option>
                      <option value="failed">Failed</option>
                      <option value="tagged_successful">Tagged Successful</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Transaction Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Transaction Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchTransactionId"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Merchant Item Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Merchant Item Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchMerchantItemId"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Amount</label>
                <div>
                  <input
                    type="tel" @input="inputNumberOnly($event, 'searchAmount')"
                    placeholder="Amount"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchAmount"
                  />
                </div>
              </div>
              <div class="col-12 col-md-auto text-md-end mb-3 mb-lg-0  d-none">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="d-flex w-100">
                  
                  <button
                      class="btn btn-light col-auto mb-0 me-2 text-nowrap d-grid"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <span><i class="uil-redo me-1"></i> Clear</span>
                    </button>
                    <button
                      class="btn btn-info text-white me-0 text-nowrap d-grid"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <span><i class="uil-search me-1"></i> Search</span>
                    </button>
                    
                </div>

              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-12 col-xl d-none">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-md col-xl mb-0 d-none">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-xl d-none">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <financialGroup ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}"  @callParentFunction="handlePageChange(currentPage)" />
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import financialGroup from "@/views/pages/app/modal/createEditGroup";
/**
 * Page
 */
const PageTitle = "Financial Group"
const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker,
    financialGroup
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      defaultName: appConfig.defaultAccountName,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      currencySymbol:"₹",
      seletedMethod: "",
      selectedStatus:"",
      searchTransactionId: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnTotalAmount: 0,
      returnTotalTransaction:0,
      returnSuccessRate: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      currentAccount:{},
      currentMerchantInfo:{},
      currentResellerInfo:{},
      updateData:{},
      create_modify_permission:false,
    };
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
  
    // if (this.account_type == 'admin'){
    //   this.getReseller();
    // }
    this.currentAccount = {
      "account_db_code": "",
      "account_name": "Select an Account",
      "account_type": "admin",
      "up_reseller_count": 0,
      "reseller_parent": [],
      "reseller_level": "",
      "reseller_code": "",
      "merchant_count": 0
    }
    this.handlePageChange(1);
    //this.tabsChanging(0)
  }, 
  created(){
  },
  methods:{
    tabsChanging: function(e){
      console.log(this.accessUsername);
      console.log(e);
    },
    
    createGroup(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showModal(),100)
    },
    editGroup(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
    },
    viewGroup(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showDetails(),100)
    },
    viewGroupMerchant(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showDetails2(),100)
    },
    removeGroup(value) {
        Swal.fire({
          icon: 'info',
          title: `Confirm Group Deletion?`,
          html: `Are you sure you want to delete this ${value.name}? This action is irreversible, so make sure you want to proceed.`,
          confirmButtonColor: '#FA6541',
          iconColor: '#FF4500',
          showCancelButton: true,
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.loading = true
            this.disabled = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername", this.accessUsername);
            bodyFormData.append("groupDbCode", value.account_db_code);
            axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/financialGroup/deleteFinancialGroup',
              //url: appConfig.DemoAPI,
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data"
              },
            }).then((response) => {
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.handlePageChange(1)
                Swal.fire({
                  icon: 'success',
                  title: `${value.name} Deleted Successfully`,
                  html: 'The selected group has been successfully deleted.',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                })
              } else if (resData.status == 440) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                  path: "/login",
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              }
              this.loading = false
              this.disabled = false
              this.$Progress.finish();
            }).catch((error) => {
              this.disabled = false;
              this.loading = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            });
          }
        })
        
        
      },
      detectObject(value){
        if (typeof value === 'object'){
          return true
        }else{
          return false
        }
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      inputNumberOnly(event, parentModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel] = numericValue;
      },
      returnBankList(){
        if (this.selectedCountry.id !==-1){
          return this.banksList.filter(bank => bank.countryId === this.selectedCountry.id);
        }else{
          return this.banksList
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },

      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.seletedMethod= ""
        this.selectedStatus= ""
        this.searchTransactionId= ""
        this.searchMerchantItemId= ""
        this.filterType="created_date"
        this.searchAmount=""
        this.date_range = this.$refs.commonFunc.last30Days()

        this.search_value = "";
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
      if(cancel !== undefined) cancel();
      console.log("getData")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      this.disable_date = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      console.log(keyword)
      axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/financialGroup/getAllFinancialGroupList',
          // method: "get",
          // url: "/api/financial_group.json",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c)
      })
      .then((response)=>{
          this.returnData = [];
          var resData = response.data;
          if (resData.status == 200) {
            this.totalData = resData.total;
            var responseData = resData.data;
            this.returnData = responseData;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
                var transaction_id = ""
                if (randomMode=="UPI"){
                  transaction_id = "320020673657"
                }else{
                  transaction_id = "IMPS/123443211240"
                }

                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode":randomMode.toString(),
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "transaction_id":transaction_id,
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 440){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     localStorage.clear();
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          const permission = data.modules.filter(e => e.name==='administrator')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "administrator" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>