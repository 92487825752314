<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-create-group" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-collection me-1 text-primary"></i> Create New Group
           </h5>
        </template>
        <div class="row align-items-top">
            <div class="mb-3 col-12 col-md-12">
              <label>Group Name</label>
              <input
                v-model.trim="formData.name"
                type="text"
                class="form-control"
                placeholder="Name"
                name="name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.name.required"
                    >Name is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Bank &amp; UPI Account</label>
                <multiselect v-model="formData.bank_accounts" :options="accountLists" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Account" label="group_data" track-by="group_data" class="" :preselect-first="false" :disabled="modalLoading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.bank_accounts.$error,
                  }">
                 <template slot="option" slot-scope="{ option}"><span class="option__title  cursor-pointer">
                  <span v-if="option.active=='0'" class="text-warning me-1">&#9679;</span>
                  <span v-else-if="option.active=='1'" class="text-success me-1">&#9679;</span> 
                  {{ option.group_data }} </span>
                 </template>
                 
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 my-1 bg-primary text-white cursor-pointer">{{ option.group_data }} <span class="custom__remove" @click="remove(option)"><i class="bi bi-x-circle ms-1"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                
                 <div v-if="modalSubmit && $v.formData.bank_accounts.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.bank_accounts.required">Account is required.</span>
                </div>
            </div>
            <div class="col-12">
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table align-middle mb-0" :class="{'table-hover':formEditData.bank_accounts.length}">
              <thead class="text-uppercase">
                <tr>
                  <th scope="col" class="fw-medium">Account No. / UPI ID</th>
                  <th scope="col" class="fw-medium">Account Name</th>
                  <th scope="col" class="fw-medium">Bank Name</th>
                  <th scope="col" class="fw-medium">IFSC Code</th>
                  <th scope="col" class="fw-medium">Type</th>
                  <th scope="col" class="fw-medium text-center">Action</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
               
                <tr v-for="(value, index) in formData.bank_accounts" :key="'main_'+index">
                  <td>{{ value.account_id }}</td>
                  <td>{{ value.account_name }}</td>
                  <td>{{ value.bank_name || '-' }}</td>
                  <td>{{ value.ifsc_code || '-' }}</td>
                  <td>{{ value.type }}</td>
                  <td class="text-center"><span @click="formData.bank_accounts.splice(index, 1);" class="text-primary fw-medium cursor-pointer"><i class="bi bi-x-circle me-1"></i> Remove</span></td>
                </tr>
                <tr v-if="!formData.bank_accounts.length && !modalLoading">
                    <td colspan="6" height="200" class="text-center text-muted">
                      <i class="bi bi-credit-card me-1"></i> No Data
                      Available
                    </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
            <div class="col-12">
                <hr>
                <div class="d-grid gap-2 d-md-block text-end">
                  <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-create-group'].hide()">Cancel</button>
                  <button type="button" class="btn fw-medium btn-info" @click="addRequest" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Creating...</span>
                  <span v-else> Create </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                  </button>
                </div>
            </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-edit-group" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-collection me-1 text-primary"></i> Edit {{formEditData.name}}'s Group
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-md-12">
              <label>Group Name</label>
              <input
                v-model.trim="formEditData.name"
                type="text"
                class="form-control"
                placeholder="Name"
                name="name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.name.required"
                    >Name is required.</span
                    >
              </div>
          </div>
          <div class="mb-3 col-12">
            <div class="align-items-center">
              <label class="mb-1 me-2">Group Status</label>
              <div>
                <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                  <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="available" false-value="unavailable" :disabled="modalLoading">
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Bank &amp; UPI Account</label>
                <multiselect v-model="formEditData.bank_accounts_new" @input="returnData=formEditData.bank_accounts" :options="accountLists" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select UPI Account" label="group_data" track-by="group_data" class="" :preselect-first="false" :disabled="modalLoading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.bank_accounts.$error,
                  }">
                 <template slot="option" slot-scope="{ option}"><span class="option__title  cursor-pointer">
                  <span v-if="option.active=='0'" class="text-warning me-1">&#9679;</span>
                  <span v-else-if="option.active=='1'" class="text-success me-1">&#9679;</span> 
                  {{ option.group_data }} </span>
                 </template>
                 
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 my-1 bg-primary text-white cursor-pointer">{{ option.group_data }}  <span class="custom__remove" @click="remove(option)"><i class="bi bi-x-circle ms-1"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                
                 <div v-if="modalSubmit && $v.formEditData.bank_accounts.$error" class="invalid-feedback">
                  <span v-if="!$v.formEditData.bank_accounts.required">UPI Account is required.</span>
                </div>
          </div>
          <div class="col-12">
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table align-middle mb-0" :class="{'table-hover':formEditData.bank_accounts.length}">
              <thead class="text-uppercase">
                <tr>
                  <th scope="col" class="fw-medium">Account No. / UPI ID</th>
                  <th scope="col" class="fw-medium">Account Name</th>
                  <th scope="col" class="fw-medium">Bank Name</th>
                  <th scope="col" class="fw-medium">IFSC Code</th>
                  <th scope="col" class="fw-medium">Type</th>
                  <th scope="col" class="fw-medium">All Group Active</th>
                  <th scope="col" class="fw-medium">Active In Group</th>
                  <th scope="col" class="fw-medium text-center">Action</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
               
                <tr v-for="(value, index) in formEditData.bank_accounts" :key="'main_'+index">
                  <td>{{ value.account_id }}</td>
                  <td>{{ value.account_name }}</td>
                  <td v-if="value.type=='UPI'">-</td>
                  <td v-else>{{ value.bank_name || '-' }}</td>
                  <td>{{ value.ifsc_code  || '-' }}</td>
                  <td>{{ value.type }}</td>
                  <td>
                    <!-- <div v-if="create_modify_permission" class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active" true-value="1" false-value="0" :disabled="modalLoading">
                    </div>
                    <div v-else class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active" true-value="1" false-value="0" disabled="true">
                    </div> -->
                    <span v-if="value.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                     <span v-else-if="value.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                  </td>
                  <td>
                    <div v-if="create_modify_permission && (value.active==1)" class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active_in_group" true-value="1" false-value="0" :disabled="modalLoading">
                    </div>
                    <div v-else class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active_in_group" true-value="1" false-value="0" disabled="true">
                    </div>
                  </td>
                  <td class="text-center">-</td>
                </tr>
                <tr v-for="(value, index) in formEditData.bank_accounts_new" :key="index">
                  <td>{{ value.account_id }}</td>
                  <td>{{ value.account_name }}</td>
                  <td>{{ value.bank_name || '-' }}</td>
                  <td>{{ value.ifsc_code|| '-' }}</td>
                  <td>{{ value.type }}</td>
                  <td>
                    <!-- <div v-if="create_modify_permission" class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active" true-value="1" false-value="0" :disabled="modalLoading">
                    </div>
                    <div v-else class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active" true-value="1" false-value="0" disabled="true">
                    </div> -->
                    <span v-if="value.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                     <span v-else-if="value.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                  </td>
                  <td>
                    <div v-if="create_modify_permission && (value.active==1)" class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active_in_group" true-value="1" false-value="0" :disabled="modalLoading">
                    </div>
                    <div v-else class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active_in_group" true-value="1" false-value="0" disabled="true">
                    </div>
                  </td>
                  <td class="text-center"><span @click="formEditData.bank_accounts_new.splice(index, 1);" class="text-primary fw-medium cursor-pointer"><i class="bi bi-x-circle me-1"></i> Remove</span></td>
                </tr>
                <tr v-if="!formEditData.bank_accounts.length && !modalLoading">
                    <td colspan="8" height="200" class="text-center text-muted">
                      <i class="bi bi-credit-card me-1"></i> No Data
                      Available
                    </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-edit-group'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-details" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-credit-card me-1 text-primary"></i> {{ updateData.name }}'s UPI Account
           </h5>
        </template>
        <div class="row align-items-center">
          <div class="col-12">
            <div class="d-flex">
                <div class="app-search2 position-relative">
                  <input
                    type="search"
                    placeholder="Filter..."
                    class="form-control mb-3"
                    v-model.trim="searchKey"
                    autocomplete="no"
                    @keyup="filterByUPI()"
                  />
                  <span class="uil-search text-info"></span>
                </div>
                <button
                  class="btn btn-light mb-3 ms-2 text-nowrap"
                  type="button"
                  @click="searchKey=''"
                >
                  <i class="uil-redo me-1"></i> Reset
              </button>
            </div>
          </div>
          <div class="col-12">
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table align-middle mb-0" :class="{'table-hover':filterByUPI().length>0 && !modalLoading}">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Account No. / UPI ID</th>
                  <th scope="col" class="fw-medium">Account Name</th>
                  <th scope="col" class="fw-medium">Bank Name</th>
                  <th scope="col" class="fw-medium">IFSC Code</th>
                  <th scope="col" class="fw-medium">Type</th>
                  <th scope="col" class="fw-medium">Transaction Today</th>
                  <th scope="col" class="fw-medium">All Group Transactions</th>
                  <th scope="col" class="fw-medium">All Group Limit</th>
                  <th scope="col" class="fw-medium">All Group Active</th>
                  <th scope="col" class="fw-medium">Active In Group</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
               
                <tr v-for="(value, index) in filterByUPI()" :key="index">
                  <td>{{ value.account_id }}</td>
                  <td>{{ value.account_name }}</td>
                  <td v-if="value.type=='UPI'">-</td>
                  <td v-else>{{ value.bank_name || '-' }}</td>
                  <td>{{ value.ifsc_code || '-'}}</td>
                  <td>{{ value.type }}</td>
                  <td><span v-if="updateData.currency">{{ updateData.currency.symbol }}</span> {{ convertCurrencyFormat(value.transaction_spent_today,true) }}</td>
                  <td><span v-if="updateData.currency">{{ updateData.currency.symbol }}</span>  {{ convertCurrencyFormat(value.transaction_spent,true) }}</td>
                  <td><span v-if="updateData.currency">{{ updateData.currency.symbol }}</span>  {{ convertCurrencyFormat(value.transaction_limit,false) }}</td>
                  <td>
                    <!-- <div v-if="create_modify_permission" class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" @change="updateUPI(value)" role="switch" v-model="value.active" true-value="1" false-value="0" :disabled="modalLoading">
                    </div>
                    <div v-else class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active" true-value="1" false-value="0" disabled="true">
                    </div> -->
                    <span v-if="value.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                     <span v-else-if="value.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                  </td>
                  <td>
                    <div v-if="create_modify_permission && (value.active==1)" class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active_in_group" true-value="1" false-value="0" :disabled="modalLoading" @change="updateUPI(value)" >
                    </div>
                    <div v-else class="form-check form-switch form-check-custom font-size-14 text-nowrap">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="value.active_in_group" true-value="1" false-value="0" disabled="true">
                    </div>
                  </td>
                </tr>
                <tr v-if="!filterByUPI().length && !loading && searchKey">
                  <td colspan="7" height="200" class="text-center text-muted">
                    <i class="uil uil-usd-circle me-1"></i>  Sorry, we couldn't
                    find any results of "{{ searchKey }}".
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
         
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-details'].hide()">Cancel</button>
              </div>
          </div>
        </div>
     </b-modal>

     <b-modal @hidden="resetModal" ref="modal-merchants" size="lg" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-people me-1 text-primary"></i> {{ updateData.name }}'s Merchants
           </h5>
        </template>
        <div class="row align-items-center">
          <div class="col-12">
            <div class="d-flex">
                <div class="app-search2 position-relative">
                  <input
                    type="search"
                    placeholder="Filter..."
                    class="form-control mb-3"
                    v-model.trim="searchKey"
                    autocomplete="no"
                    @keyup="filterByMerchant()"
                  />
                  <span class="uil-search text-info"></span>
                </div>
                <button
                  class="btn btn-light mb-3 ms-2 text-nowrap"
                  type="button"
                  @click="searchKey=''"
                >
                  <i class="uil-redo me-1"></i> Reset
              </button>
            </div>
          </div>
          <div class="col-12">
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table align-middle mb-0" :class="{'table-hover':filterByMerchant().length>0 && !modalLoading}">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Merchant ID</th>
                  <th scope="col" class="fw-medium">Merchant Name</th>
                  <th scope="col" class="fw-medium">Merchant Code</th>
                  <th scope="col" class="fw-medium">Status</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr v-for="(value, index) in filterByMerchant()" :key="index">
                  <td>{{ value.id }}</td>
                  <td>{{ value.account_name }}</td>
                  <td>{{ value.merchant_code }}</td>
                  <td>
                    <span v-if="value.status=='inactive'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                     <span v-else-if="value.status=='active'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                  </td>
                </tr>
                <tr v-if="!filterByMerchant().length && !loading && searchKey">
                  <td colspan="4" height="200" class="text-center text-muted">
                    <i class="uil uil-users-alt me-1"></i>  Sorry, we couldn't
                    find any results of "{{ searchKey }}".
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
         
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchants'].hide()">Cancel</button>
              </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    minLength, 
    //url
  } from "vuelidate/lib/validators";

  /**
   * Starter page
   */
  
  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        updateData: {
          preview_username:""
        },
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        create_modify_permission:false,
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        returnData: [],
        returnData2: [],
        isEdit:false,
        accountLists: [],
        searchKey:"",
        formData: {
          name:"",
          bank_accounts:[],
        },
        formEditData: {
          id:"",
          name:"",
          status:"",
          bank_accounts:[],
          bank_accounts_new:[],
        },
        currentModules:[]
      };
    },
    validations: {
      formData: {
        name: {
          required,
        },
        bank_accounts: {
          required,
          minLength: minLength(1),
        }
      },
      formEditData: {
        name: {
          required,
        },
        bank_accounts: {
          required,
          minLength: minLength(1),
        }
      },

    },
    middleware: "authentication",
    async mounted() {
      this.reload();
    },
    created() {
      
    },
    methods: {
      removeElements(array, idsToRemove) {
          return array.filter(item => !idsToRemove.includes(item.account_db_code));
      },
      getAccountList(){
        console.log("getAccountList")
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        
        axios({
            method: "post",
            url: appConfig.APIHostAdmin +  'controller/financialGroup/getAllImpsUpiAccounts',
            //url: '/api/accounts_list.json',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.accountLists = []
              
              var idsToRemove = [];
              
              if (this.isEdit){
                console.log(this.formEditData.bank_accounts)
                this.formEditData.bank_accounts.forEach(element => {
                  idsToRemove.push(element.account_db_code);
                });
                var newData = this.removeElements(resData.data, idsToRemove);

                newData.forEach(element => {
                  if (element.active==1){
                    var groupData = "";
                    if (element.bank_name != ""){
                      groupData = `${element.account_name} - ${element.account_id} (${element.bank_name})`
                    }else{
                      groupData =  `${element.account_name} - ${element.account_id}`
                    }
                    this.accountLists.push({
                      group_data: groupData,
                      account_id:  element.account_id,
                      account_db_code:  element.account_db_code,
                      account_name: element.account_name,
                      bank_name: element.bank_name,
                      ifsc_code: element.ifsc_code,
                      type: element.type,
                      transaction_limit: element.transaction_limit,
                      transaction_spent: element.transaction_spent,
                      transaction_spent_in_group: 0,
                      active: element.active,
                      active_in_group: 1
                    })
                  }
                });
              }else{
                resData.data.forEach(element => {
                  if (element.active==1){
                    var groupData = "";
                    if (element.bank_name != ""){
                      groupData = `${element.account_name} - ${element.account_id} (${element.bank_name})`
                    }else{
                      groupData =  `${element.account_name} - ${element.account_id}`
                    }
                    this.accountLists.push({
                      group_data: groupData,
                      account_id:  element.account_id,
                      account_db_code:  element.account_db_code,
                      account_name: element.account_name,
                      bank_name: element.bank_name,
                      ifsc_code: element.ifsc_code,
                      type: element.type,
                      transaction_limit: element.transaction_limit,
                      transaction_spent: element.transaction_spent,
                      transaction_spent_in_group: 0,
                      active: element.active,
                      active_in_group: 1
                    })
                  }
                 
               });
              }
              
             
             
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      filterByUPI() {
        return this.returnData.filter((e) => {
            return (
            e.account_id.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            e.account_name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            e.bank_name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            e.type.toLowerCase().includes(this.searchKey.toLowerCase())
            );
        })
      },
      filterByMerchant() {
        return this.returnData2.filter((e) => {
            return (
            e.account_username.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            e.account_name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            e.merchant_code.toLowerCase().includes(this.searchKey.toLowerCase())
            );
        })
      },
    
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel][childModel] = val;
        }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-create-group'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.isEdit = false;
        this.getAccountList();
      },
      showEditModal() {
        this.$refs['modal-edit-group'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.isEdit = true;
        this.getAccountList();
        const bankAccount = []
        this.data.updateData.bank_list.forEach(element => {
          var groupData = "";
          if (element.bank_name != ""){
            groupData = `${element.account_name} - ${element.account_id} (${element.bank_name})`
          }else{
            groupData =  `${element.account_name} - ${element.account_id}`
          }
          bankAccount.push({
            group_data: groupData,
            account_id:  element.account_id,
            account_db_code:  element.account_db_code,
            account_name: element.account_name,
            bank_name: element.bank_name,
            ifsc_code: element.ifsc_code,
            type: element.type,
            transaction_limit: element.transaction_limit,
            transaction_spent: element.transaction_spent,
            transaction_spent_in_group: 0,
            active: element.active,
            active_in_group: element.active_in_group
          })
        });
        this.formEditData =  {
          id: this.data.updateData.account_db_code,
          name: this.data.updateData.name,
          status: this.data.updateData.status,
          bank_accounts: bankAccount,
          bank_accounts_new: []
        }
        this.returnData = this.updateData.bank_list
      },
      showDetails() {
        this.$refs['modal-details'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData = this.updateData.bank_list
      },
      showDetails2() {
        this.$refs['modal-merchants'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData2 = this.updateData.merchants
      },
      resetModal() {
        this.searchKey = "";
        this.returnData = []
        this.returnData2 = []
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          name:"",
          bank_accounts:[],
        },
        this.formEditData= {
          id:"",
          name:"",
          status:"",
          bank_accounts:[],
          bank_accounts_new:[],
        }
      },
      addRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("groupName", this.formData.name);
          bodyFormData.append("groupList", JSON.stringify(this.formData.bank_accounts));
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/financialGroup/addNewFinancialGroup',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Group Created',
                  html: 'Group has been successfully created!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-create-group'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))

        var combinedArray = []
        console.log(this.formEditData)
        if (this.formEditData.bank_accounts_new.length > 0){
          combinedArray = this.formEditData.bank_accounts.concat(this.formEditData.bank_accounts_new);
        }else{
          combinedArray = this.formEditData.bank_accounts
        }

        if (this.$v.formEditData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("groupDbCode", this.formEditData.id);
          bodyFormData.append("groupName", this.formEditData.name);
          bodyFormData.append("groupStatus", this.formEditData.status);
          bodyFormData.append("groupList", JSON.stringify(combinedArray));
          axios({
              method: "post",
              url: appConfig.APIHostAdmin +  'controller/financialGroup/updateFinancialGroup',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Group Updated',
                  html: 'Group has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-edit-group'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateUPI(value){
        this.$Progress.start();
        this.modalLoading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("groupDbCode", this.updateData.account_db_code);
        bodyFormData.append("accountDbCode", value.account_db_code);
        bodyFormData.append("status",value.active_in_group);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/financialGroup/updateFinancialGroupItemStatus', 
            //url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
          var resData = response.data;
          //this.returnData = []
          if (resData.status == 200) {
            console.log(resData)
            value.active = (value.active==1)? 1 : 0;
            //this.$refs['modal-details'].hide();
            this.$emit('callParentFunction');
          } 
          else if (resData.status == 440){
            value.active = (value.active==1)? 0 : 1;
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.modalLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.modalLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='administrator')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "administrator" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  label{
    margin-bottom: 5px;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>